import React from 'react';

import './App.scss';

import Main from './pages/Main/Main'

function App() {

  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
